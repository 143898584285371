import axios from "axios";

//admins
export const admins = (obj) => {
    return axios
        .get("/admin", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get admin
export const getAdmin = (obj) => {
    return axios
        .get("/admin/" + obj.admin_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//update admin
export const updateAdmin = (obj) => {
    return axios
        .patch("/admin/" + obj.admin_id, obj.data, {
            headers: {
                Authorization: `Bearer ${obj.token}`,
                "content-type": "multipart/form-data"
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//delete admin
export const deleteAdmin = (obj) => {
    return axios
        .delete("/admin/" + obj.admin_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};