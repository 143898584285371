import { AccordionDetails, Button, Grid, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { validateACno, validateIFSC, validateText } from "../Common/validations";
import { bankDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import Swal from 'sweetalert2'
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";
function CustBank(props) {
    const { adminData, id, edit } = props;
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})

    function onChangeData(e) {
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        const asyncFn = async () => {
            const response = await getCustomer({
                customer_id: id,
                token: adminData?.accessToken || getLocalStorage('rv-admin')?.accessToken
            })
            if (response?.status === 200) {
                if (response.data?.bank[0]) {
                    setData(response.data.bank[0]);
                } else {
                    setData({
                        ac_no: '',
                        bank_name: '',
                        branch_name: '',
                        ifsc_code: '',
                        razorpay_id: ''
                    })
                }
            }
        }
        try {
            asyncFn();
        } catch (e) {
            navigate("/login")
        }
    }, [adminData, id, edit])

    function validateAC(str) {
        setErrors((errors) => ({
            ...errors,
            ac_no: validateACno(str).error,
        }));
    }
    function validateBankNAme(str) {
        setErrors((errors) => ({
            ...errors,
            bank_name: validateText(str).error,
        }));
    }
    function validateBranchName(str) {
        setErrors((errors) => ({
            ...errors,
            branch_name: validateText(str).error,
        }));
    }
    function validateIfsc(str) {
        setErrors((errors) => ({
            ...errors,
            ifsc_code: validateIFSC(str).error,
        }));
    }

    function validateForm() {
        validateAC(data.ac_no)
        validateBankNAme(data.bank_name)
        validateBranchName(data.branch_name)
        validateIfsc(data.ifsc_code)
        let valid = validateACno(data.ac_no).valid
            && validateText(data.bank_name).valid
            && validateText(data.branch_name).valid
            && validateIFSC(data.ifsc_code).valid;
        return valid;
    }

    async function saveData(e) {
        e.preventDefault();
        if (validateForm()) {
            const response = await bankDetails({
                token: adminData?.accessToken || getLocalStorage('rv-admin')?.accessToken,
                data: {
                    id: id,
                    ac_no: data.ac_no.toString(),
                    bank_name: data.bank_name,
                    branch_name: data.branch_name,
                    ifsc_code: data.ifsc_code
                },
            });
            if (response?.status === 200) {
                Swal.fire(
                    'Good Job!',
                    'Customer data updated successfully',
                    'success'
                )
            }
        }
    }


    return (
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Account No</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="ac_no"
                                value={data.ac_no}
                                onChange={onChangeData}
                                onBlur={() => validateAC(data.ac_no)}
                            /> : data.ac_no}
                                {errors.ac_no && edit && (
                                    <Typography className='error-text'>{errors.ac_no}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>IFSC Code</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="ifsc_code"
                                value={data.ifsc_code}
                                onChange={onChangeData}
                                onBlur={() => validateIfsc(data.ifsc_code)}
                            /> : data.ifsc_code}
                                {errors.ifsc_code && edit && (
                                    <Typography className='error-text'>{errors.ifsc_code}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Bank Name</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                value={data.bank_name}
                                name="bank_name"
                                onChange={onChangeData}
                                onBlur={() => validateBankNAme(data.bank_name)}
                            /> : data.bank_name}
                                {errors.bank_name && edit && (
                                    <Typography className='error-text'>{errors.bank_name}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Branch Name</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                value={data.branch_name}
                                name="branch_name"
                                onChange={onChangeData}
                                onBlur={() => validateBranchName(data.branch_name)}
                            /> : data.branch_name}
                                {errors.branch_name && edit && (
                                    <Typography className='error-text'>{errors.branch_name}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Razorpay ID</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                value={data.razorpay_id}
                                name="razorpay_id"
                                onChange={onChangeData}
                            // onBlur={() => validateAdd1(data.razorpay_id)}
                            /> : data.razorpay_id}
                                {errors.razorpay_id && edit && (
                                    <Typography className='error-text'>{errors.razorpay_id}</Typography>
                                )}
                            </TableCell>
                        </TableRow>

                    </Table>
                    {edit && <Button className='primary-btn' variant="contained" onClick={saveData} > Save</Button>}

                </Grid>
            </Grid>
        </AccordionDetails>
    )
}
export default CustBank;