import axios from "axios";

//add insurance
export const addInsurance = (obj) => {
    return axios
        .post("/insurance", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

export const updateInsurance = (obj) => {
    return axios
        .patch("/insurance", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//insurances
export const insurances = (obj) => {
    return axios
        .get("/insurance", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get insurance
export const getInsurance = (obj) => {
    return axios
        .get("/insurance/" + obj.insurance_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//read insurance
export const readInsurance = (obj) => {
    return axios
        .patch("/insurance/read", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};