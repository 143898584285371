import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Checkbox } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { visuallyHidden } from '@mui/utils';

import "./CustomTable.css";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    // if order desc sort descending order or ascending order
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    // this function will sort array
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function CustomTableHead(props) {
    // This comonent will show head of table
    const { order, orderBy, headCells, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        // change type or property of sorting
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.findIndex(v => v.id === "copy_all" || v.id === "delete_all") !== -1 ?
                    <TableCell padding="checkbox" width="10px"></TableCell>
                    : null
                }
                {headCells.map((headCell, index) => {
                    if (headCell?.noDisplay) {
                        return <TableCell sx={{ display: "none" }} key={index}></TableCell>;
                    }
                    return (
                        <TableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false} >
                            {/* sort icon */}
                            {headCell.noSort ? headCell.label :
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            }
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

CustomTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
};

function CustomTable(props) {
    // this component will show table
    const { rows, headCells, content, defaultRows, filters, hideDelete } = props;
    // headcells: array of object of prototype of data, rows: array of object of data according to headcells
    // defaultRows: no of rows to show by default, content: special array of object to show inside perticual columns
    const navigate = useNavigate()
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(headCells[0]?.id || '0');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState((defaultRows === "MAX" ? rows?.length : defaultRows) || 5);
    const [selected, setSelected] = React.useState(new Array(rows?.length).fill(null));
    const [showDeleteSelected, setShowDeleteSelected] = React.useState(false);
    const [userData, setUserData] = React.useState({})

    React.useEffect(() => {
        // for selecting multiple rows initialize according to no of rows
        setSelected(new Array(rows?.length).fill(null));
    }, [rows])

    React.useEffect(() => {
        // for showing delete selected button or not
        if (selected.reduce(
            (previousValue, currentValue) => previousValue || currentValue,
            false
        )) {
            setShowDeleteSelected(true);
        } else {
            setShowDeleteSelected(false);
        }
    }, [selected])

    const handleRequestSort = (event, property) => {
        // sorting according to property
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        // change page
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // change no of rows to display in one page
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const changeSelected = (data, index) => {
        // handle checkbox for selected
        let temp = [...selected];
        temp[index] = temp[index] ? null : data;
        setSelected(temp);
    }

    const deleteAll = () => {
        // callback delete selected function
        let remainder = rows.length % rowsPerPage;
        let last = selected.slice(-remainder).findIndex(i => i === null);
        let total = selected.filter(res => res !== null).length
        headCells.find(v => v.id === "delete_all").callback(selected);
        if (total > rowsPerPage || (page === Math.floor(rows.length / rowsPerPage) && last === -1)) {
            setPage(0);
        }
    }

    const copyAll = () => {
        headCells.find(v => v.id === "copy_all").callback(selected);
    }

    const callCallback = (callback, row, d) => {
        let remainder = rows.length % rowsPerPage;
        let totalPages = Math.floor(rows.length / rowsPerPage);
        if (!d) {
            return callback(row)
        }
        callback({
            ...row, callback: () => {
                // console.log(remainder, totalPages, remainder === 1 && page === totalPages)
                if (remainder === 1 && page === totalPages) {
                    setPage(0);
                }
            }
        })
    }

    return (
        <Box sx={{
            p: 2, pb: 0, pt: 0,
            // boxShadow: "0px 0px 5px #888888",
            borderRadius: "10px",
            backgroundColor: "white",
            width: "100%"
        }}
            className="custom-table" >
            <Box className='w-100'>
                <TableContainer>
                    <Table
                        sx={{ mb: 3 }}
                        aria-labelledby="tableTitle"
                        size="small" >
                        <CustomTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells} />
                        <TableBody>

                            {filters}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <>
                                            <TableRow
                                                className= {row.hasOwnProperty('read_time') && !row.read_time ? 'pending' : ''}
                                                hover
                                                tabIndex={-1}
                                                key={index} >
                                                {headCells.findIndex(v => v.id === "copy_all" || v.id === "delete_all") !== -1 ?
                                                    <TableCell padding="checkbox" className='checkbox' width="10px">
                                                        {!hideDelete ?
                                                            <Checkbox
                                                                color="primary"
                                                                checked={!!selected[page * rowsPerPage + index]}
                                                                onChange={() => changeSelected(row, page * rowsPerPage + index)} /> : <div style={{ width: "40px" }}></div>}
                                                    </TableCell> : null
                                                }
                                                {headCells.map((cell, i) => {
                                                    const isNumberString = (str) => /^\d+$/.test(str);
                                                    if (cell?.noDisplay) {
                                                        return <TableCell sx={{ display: "none" }} key={i}></TableCell>;
                                                    }
                                                    else if (cell.type === "button") {
                                                        let d = null;
                                                        if (cell.id === "delete") {
                                                            d = true;
                                                        }
                                                        let Icon = cell.icon;
                                                        return (<TableCell key={i}>
                                                            <Icon sx={{ cursor: "pointer", ...cell.iconStyle }} onClick={() => callCallback(cell.callback, row, d)} />
                                                            {cell.subIcons ? cell.subIcons.map((value, i) => {
                                                                let Icon = value.icon;
                                                                return <Icon
                                                                    key={i}
                                                                    sx={{ cursor: "pointer", ml: 1, ...value.iconStyle }}
                                                                    onClick={() => value.callback(row)} />
                                                            }) : null}
                                                        </TableCell>)
                                                    } else {
                                                        let value = row[cell.id];
                                                        if (cell?.content) {
                                                            value = <>
                                                                <strong>{value}</strong>
                                                                <br />
                                                                {content[cell.id][index]}
                                                            </>
                                                        }
                                                        return (<TableCell key={i} style={{ textDecoration: "none", color: "#000",textAlign: isNumberString(value) ? 'right' : 'left' }} component={row.link ? Link : 'div'} to={row.link && row.link} >
                                                            {value}
                                                        </TableCell>)
                                                    }
                                                })}
                                            </TableRow>
                                        </>

                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className='align-items-center justify-content-between' sx={{ paddingBottom: 1 }} >
                    {
                        showDeleteSelected && headCells.findIndex(v => v.id === "copy_all") !== -1 &&
                        <div className='align-items-center table-selected' style={{ color: "#202a4c", width: '250px' }} onClick={copyAll}>
                            <FileCopyIcon sx={{ color: "#202a4c" }} /> COPY SELECTED
                        </div>
                    }
                    {
                        showDeleteSelected && headCells.findIndex(v => v.id === "delete_all") !== -1 &&
                        <div className='align-items-center table-selected cursor-pointer' style={{ color: "red", width: '270px' }} onClick={deleteAll}>
                            <DeleteForeverIcon sx={{ color: "E6BE00" }} /> DELETE SELECTED
                        </div>
                    }
                    {defaultRows !== "MAX" ?
                        <TablePagination
                            className='w-100'
                            sx={{ m: 0 }}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> : null
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default CustomTable;