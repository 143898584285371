import {
  AccordionDetails,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  validateEmail,
  validatePincode,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { professionalDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import Swal from "sweetalert2";
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";
import { getLocation } from "../api/location";
function CustOccupation(props) {
  const { adminData, id, edit } = props;
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [otype, setOtype] = useState(null);
  function onChangeData(e) {
    if (e.target.name === "company_pincode") {
      setLocation(e.target.value);
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const response = await getCustomer({
        customer_id: id,
        token:
          adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        setData(response.data);
        setOtype(response.data.occupation_type);
        if (response.data.company_pincode) {
          await setLocation(response.data.company_pincode);
        }
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, [adminData, id, edit]);

  const setLocation = async (company_pincode) => {
    if (company_pincode && company_pincode.length === 6) {
      const location = await getLocation({
        pincode: company_pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          company_city: lData.District,
          company_state: lData.State,
          company_country: lData.Country,
        }));
        setErrors((errors) => ({
          ...errors,
          company_pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          company_pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      occupation_type: validateSelect(str).error,
    }));
  }
  function validateCompanyName(str) {
    setErrors((errors) => ({
      ...errors,
      company_name: validateText(str).error,
    }));
  }
  function validateCompanyEmail(str) {
    setErrors((errors) => ({
      ...errors,
      company_email: validateEmail(str).error,
    }));
  }
  function validateCompanyAddress(str) {
    setErrors((errors) => ({
      ...errors,
      company_address: validateText(str).error,
    }));
  }
  function validateCompanyPincode(str) {
    setErrors((errors) => ({
      ...errors,
      company_pincode: validatePincode(str).error,
    }));
  }
  function validateIncome(str) {
    setErrors((errors) => ({
      ...errors,
      income_per_month: validateSalary(str).error,
    }));
  }

  function validateForm() {
    validateType(otype);
    if (otype === "Salaried") {
      validateCompanyName(data.company_name);
      validateCompanyEmail(data.company_email);
      validateCompanyAddress(data.company_address);
      validateCompanyPincode(data.company_pincode);
      validateIncome(data.income_per_month);
    }
    let valid =
      validateSelect(otype).valid &&
      (otype === "Salaried"
        ? validateText(data.company_name).valid &&
          validateEmail(data.company_email).valid &&
          validateText(data.company_address).valid &&
          validatePincode(data.company_pincode).valid &&
          validateSalary(data.income_per_month).valid
        : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.company_pincode) {
      if (validateForm()) {
        const response = await professionalDetails({
          token:
            adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
          data: {
            id: id,
            occupation_type: otype,
            income_per_month: data.income_per_month,
            company_name: data.company_name,
            company_email: data.company_email,
            company_address: data.company_address,
            company_pincode: data.company_pincode,
            company_city: data.company_city,
            company_state: data.company_state,
            company_country: data.company_country,
          },
        });
        if (response?.status === 200) {
          Swal.fire(
            "Good Job!",
            "Customer data updated successfully",
            "success"
          );
        }
      }
    }
  }
  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Type</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <RadioGroup
                    row
                    aria-labelledby="occupation_type"
                    name="occupation_type"
                    value={otype}
                    onChange={(e) => setOtype(e.target.value)}
                  >
                    <FormControlLabel
                      value="Salaried"
                      control={<Radio />}
                      label="Salaried"
                    />
                    <FormControlLabel
                      value="Non Salaried"
                      control={<Radio />}
                      label="Self Employed"
                    />
                  </RadioGroup>
                ) : otype === "Non Salaried" ? (
                  "Self Employed"
                ) : (
                  otype
                )}
                {errors.occupation_type && edit && (
                  <Typography className="error-text">
                    {errors.occupation_type}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            {otype === "Salaried" ? (
              <>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Income Per Month
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="income_per_month"
                        value={data.income_per_month}
                        onChange={onChangeData}
                        onBlur={() => validateIncome(data.income_per_month)}
                      />
                    ) : (
                      data.income_per_month
                    )}
                    {errors.income_per_month && edit && (
                      <Typography className="error-text">
                        {errors.income_per_month}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Name
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="company_name"
                        value={data.company_name}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyName(data.company_name)}
                      />
                    ) : (
                      data.company_name
                    )}
                    {errors.company_name && edit && (
                      <Typography className="error-text">
                        {errors.company_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Email
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="company_email"
                        value={data.company_email}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyEmail(data.company_email)}
                      />
                    ) : (
                      data.company_email
                    )}
                    {errors.company_email && edit && (
                      <Typography className="error-text">
                        {errors.company_email}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Address
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="company_address"
                        value={data.company_address}
                        onChange={onChangeData}
                        onBlur={() =>
                          validateCompanyAddress(data.company_address)
                        }
                      />
                    ) : (
                      data.company_address
                    )}
                    {errors.company_address && edit && (
                      <Typography className="error-text">
                        {errors.company_address}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Pin Code
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="company_pincode"
                        value={data.company_pincode}
                        onChange={onChangeData}
                        onBlur={() =>
                          validateCompanyPincode(data.company_pincode)
                        }
                      />
                    ) : (
                      data.company_pincode
                    )}
                    {errors.company_pincode && edit && (
                      <Typography className="error-text">
                        {errors.company_pincode}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization City
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {data.company_city}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization State
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {data.company_state}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Organization Country
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {data.company_country}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Yearly Revenue</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="income_per_month"
                        value={data.income_per_month}
                        onChange={onChangeData}
                        onBlur={() => validateIncome(data.income_per_month)}
                      />
                    ) : (
                      data.income_per_month
                    )}
                    {errors.income_per_month && edit && (
                      <Typography className="error-text">
                        {errors.income_per_month}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Company Name</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <TextField
                        name="company_name"
                        value={data.company_name}
                        onChange={onChangeData}
                        onBlur={() => validateCompanyName(data.company_name)}
                      />
                    ) : (
                      data.company_name
                    )}
                    {errors.company_name && edit && (
                      <Typography className="error-text">
                        {errors.company_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
          </Table>
          {edit && (
            <Button
              className="primary-btn"
              variant="contained"
              onClick={saveData}
            >
              {" "}
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
export default CustOccupation;
