import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import { Button, Typography, Grid } from "@mui/material";
import { getAdmin } from "../api/admin";
import CustomerData from "../Components/customerData";
import LoanData from "../Components/loanData";

function Customer(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/customers">
            <Button className="primary-btn" variant="contained">
              {" "}
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link
              className="text-decoration-none"
              to={`/customer?id=${id}&edit=1`}
            >
              <Button className="primary-btn" variant="contained">
                {" "}
                Edit
              </Button>
            </Link>
          )}
          {id && edit && (
            <Link className="text-decoration-none" to={`/customer?id=${id}`}>
              <Button className="primary-btn" variant="contained">
                {" "}
                Cancel
              </Button>
            </Link>
          )}
        </Grid>
        <div style={{ margin: "16px", marginRight: "0", width: "100%" }}>
          <CustomerData adminData={adminData} id={id} edit={edit} />
        </div>
        {id && (
          <>
            <Typography sx={{ ml: 2, mt: 2, mb: 1 }}>
              Requested Loans:
            </Typography>
            <LoanData socket={socket} adminData={adminData} customerId={id} noEdit />
          </>
        )}
      </Grid>
    </>
  );
}

export default Customer;
