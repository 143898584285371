import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Grid, Table, TableRow, TableCell, Paper } from "@mui/material";
import Swal from 'sweetalert2'
import RichTextEditor from 'react-rte';
import { getLocalStorage} from '../Common/cookie'
import {getAdmin} from '../api/admin'
import { getContact, readContact, replyContact} from '../api/contact'

function Contact() {
    const navigate = useNavigate()
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");

    const [adminData, setAdminData] = useState({})  //for authentication token
    const [data, setData] = useState({}) //for add, update
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)
                    if (id) {
                        const res = await getContact({
                            contact_id: id,
                            token: response.data.accessToken
                        })
                        if (res?.status === 200) {
                            setData(res.data)
                        }
                        const res1 = await readContact({
                            token: response.data.accessToken,
                            data: {
                                id: id,
                                admin_id: response.data.id
                            }
                        })
                    }
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }
        };
        try{
            asyncFn();
        }catch(e){
            navigate("/login")
        }
    }, [])


    const updateData = async (e) => {
        e.preventDefault();
        if (value !== null) {
            const response = await replyContact({
                token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken,
                data: {
                    id: id,
                    reply: value.toString('html')
                },
            });
            if (response?.status === 200) {
                Swal.fire(
                    'Good job!',
                    response.message,
                    'success'
                )
                navigate("/contacts")
            } else {
                Swal.fire(
                    'Oops!',
                    response.message,
                    'error'
                )
            }
        }
    }

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12} className="d-flex justify-content-between">
                    <Link className='text-decoration-none' to='/contacts'><Button className='primary-btn' variant="contained" > Back</Button></Link>
                </Grid>
                <Grid item xs={12}>
                    <Box component={Paper} sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", p: 2 }}>
                            <Table>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Name</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.name} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Email</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.email} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Phone</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.phone} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: "20%" }}>Message</TableCell>
                                    <TableCell style={{ width: "80%" }}>{data.message} </TableCell>
                                </TableRow>
                            </Table>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {!data.reply ?
                        <RichTextEditor value={value} onChange={handleChange} />
                        //                 <RichTextEditor
                        //   value={value}
                        //   onChange={handleChange}
                        //   toolbarConfig={{
                        //     display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
                        //     INLINE_STYLE_BUTTONS: [
                        //       { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                        //       { label: 'Italic', style: 'ITALIC' },
                        //       { label: 'Underline', style: 'UNDERLINE' }
                        //     ],
                        //     BLOCK_TYPE_DROPDOWN: [
                        //       { label: 'Normal', style: 'unstyled' },
                        //       { label: 'Heading Large', style: 'header-one' },
                        //       { label: 'Heading Medium', style: 'header-two' },
                        //       { label: 'Heading Small', style: 'header-three' }
                        //     ],
                        //     BLOCK_TYPE_BUTTONS: [
                        //       { label: 'UL', style: 'unordered-list-item' },
                        //       { label: 'OL', style: 'ordered-list-item' }
                        //     ]
                        //   }}
                        // />
                        : <div dangerouslySetInnerHTML={{ __html: data.reply }} />}
                </Grid>
                <Grid item xs={12}>
                    <Box component="form">
                        {id && !data.reply && <>
                            <Button onClick={updateData} variant="contained" className='primary-btn me-3'> Update </Button>
                            <Link className='text-decoration-none' to={`/contact?id=${id}`}><Button variant="contained" className='primary-btn'> Cancel </Button></Link>
                        </>}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Contact;
