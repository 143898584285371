import axios from "axios";

//loans
export const loans = (obj) => {
    return axios
        .get("/loan", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get loan
export const getLoan = (obj) => {
    return axios
        .get("/loan/" + obj.loan_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//read loan
export const readLoan = (obj) => {
    return axios
        .patch("/loan/read", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//update loan data
export const updateLoanData = (obj) => {
    return axios
        .post("/loan/offered", obj.data, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};