import { Accordion, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import CustPersonal from "./custPersonal";
import CustAddress from "./custAddress";
import CustBank from "./custBank";
import CustIdentity from "./custIdentity";
import CustOccupation from "./custOccupation";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";

function CustomerData(props) {
    const { adminData, id, edit } = props;
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>
                            Personal Details
                        </Typography>
                    </Grid>
                </AccordionSummary>
                <CustPersonal adminData={adminData} id={id} edit={edit}  />
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>Address Details</Typography>
                    </Grid>
                </AccordionSummary>
                <CustAddress adminData={adminData} id={id} edit={edit}  />
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>Bank Details</Typography>
                    </Grid>
                </AccordionSummary>
                <CustBank adminData={adminData} id={id} edit={edit}  />
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>Documents</Typography>
                    </Grid>
                </AccordionSummary>
                <CustIdentity adminData={adminData} id={id} edit={edit}  />
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography sx={{ flexShrink: 0 }}>
                            Occupation Details
                        </Typography>
                    </Grid>
                </AccordionSummary>
                <CustOccupation adminData={adminData} id={id} edit={edit}  />
            </Accordion> 
        </>
    )
}
export default CustomerData;