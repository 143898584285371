import axios from "axios";

//get data
export const getEmiData = (obj) => {
    return axios
        .get("/emi-calc")
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//change amount
export const changeAmountApi = (obj) => {
    return axios
        .patch("/emi-calc/amount", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//change tenure
export const changeTenureApi = (obj) => {
    return axios
        .patch("/emi-calc/tenure", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//change interest
export const changeInterestApi = (obj) => {
    return axios
        .patch("/emi-calc/interest", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
