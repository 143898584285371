import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { subscribes } from "../api/subscribe"
import { Button, Grid, TableRow, TableCell, TextField, Tooltip, IconButton, Paper, Box, Chip, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomTable from '../Common/CustomTable';
import { getLocalStorage} from '../Common/cookie'
import { getAdmin } from "../api/admin";

function Subscribers(props) {
    const { socket } = props;
    const navigate = useNavigate()
    const [adminData, setAdminData] = useState({})
    const [search, setSearch] = useState({
        email: ''
    })
    const [data, setData] = useState([])
    const [clear, setClear] = useState(false)

    useEffect(() => {
        socket.on('sub', (newObject) => {
            setData(data => {
                const filteredArray = data.filter(obj => obj.id !== newObject.id);
                return [...filteredArray, newObject];
            });
        });
     }, [socket])

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)
                    getAllSubscribers('')
                }
            } else {
                navigate("/login")
            }
        };
        try{
            asyncFn();
        }catch(e){
            navigate("/login")
        }
    }, [])

    const getAllSubscribers = async (search) => {
        const res = await subscribes({ token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken })
        if (res?.status === 200) {
            let filtered = res.data;
            if (search.email) {
                filtered = filtered.filter(d => {
                    return d.email.match(`.*${search.email}.*`);
                });
            }
            setData(filtered)
        }
    }

    useEffect(() => {
        getAllSubscribers(search)
    }, [search]);

    const createRows = (data) => {
        const headCells = [
            {
                id: 'id',
                label: 'Id',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'delete_all',
                noDisplay: true
            },
        ]
        function createData(id, email) {
            return {
                id, email
            };
        }

        const rows = data.map((value) => {
            return (createData(
                value.id,
                value.email
            ))
        });

        const filters = <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell>
                    {clear ?
                        <Tooltip title="Clear Filters" arrow>
                            <IconButton onClick={clearFilter}>
                                <CancelIcon className='primary-text' />
                            </IconButton>
                        </Tooltip>
                        :
                        <></>
                    }
                </TableCell>
                <TableCell></TableCell>
                <TableCell   >
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="email"
                        value={search.email}
                        onChange={onChangeData} />
                </TableCell>
            </TableRow>
        </>

        return { headCells, rows, filters };
    }

    function clearFilter() {
        setSearch({
            email: ''
        })
    }

    function onChangeData(e) {
        setSearch((search) => ({
            ...search,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        let flag = 0;
        Object.keys(search).forEach(function (key) {
            if (search[key] !== '') {
                flag = 1;
            }
        });
        flag === 0 ? setClear(false) : setClear(true);
    }, [search])
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="d-flex justify-content-end">
                    <Link className='text-decoration-none' to='/subscriber?edit=1'><Button className='primary-btn' variant="contained" > Send Mail</Button></Link>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box component={Paper} sx={{ pt: 2 }}>
                        {/* {data.length > 0 ? */}
                        <CustomTable {...createRows(data)} hideDelete={true} />
                        {/* :
                            <Box sx={{ p: 3 }}><h5>You have not added any admin.</h5></Box>
                        } */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Subscribers;