export function convertUTCToIST(utcDateTimeString) {
    // Parse the UTC date-time string
    const utcDate = new Date(utcDateTimeString);
  
    // Get the IST offset in milliseconds (5 hours 30 minutes)
    const istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
  
    // Convert UTC date to IST by adding the offset
    const istDate = new Date(utcDate.getTime() + istOffset);
  
    // Get the IST date components
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, '0');
    const hours = String(istDate.getHours()).padStart(2, '0');
    const minutes = String(istDate.getMinutes()).padStart(2, '0');
    const seconds = String(istDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, '0');
  
    // Format the IST date-time string
    const istDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  
    return istDateTimeString;
  }