import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "../Components/menu";
import Error from './error';
import Home from './dashboard';
import CibilScore from "./cibilScore";
import CibilScores from "./cibilScores";
import Contact from "./contact";
import Contacts from "./contacts";
import Customer from "./customer";
import Customers from "./customers";
import Employee from "./employee";
import Employees from "./employees";
import Insurance from "./insurance";
import Insurances from "./insurances";
import Loan from "./loan";
import Loans from "./loans";
import Subscriber from "./subscriber";
import Subscribers from "./subscribers";
import Extra from "./extra";
import Login from "./login";
import ChangePass from "./changePass";
import Profile from "./profile";

import io from 'socket.io-client';
import { useEffect, useState } from "react";
import CCs from "./ccs";

const socket = io(process.env.REACT_APP_SOCKET);
export default function App() {
    useEffect(() => {
        socket.on('connect', () => {
            console.log('Connected to server');
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Menu name="Dashboard" socket={socket}><Home socket={socket} /></Menu>} />
                <Route path="/home" element={<Menu name="Dashboard" socket={socket}><Home socket={socket} /></Menu>} />
                <Route path="/employee" element={<Menu name="Employee" socket={socket}><Employee /></Menu>} />
                <Route path="/employees" element={<Menu name="Employees" socket={socket}><Employees socket={socket} /></Menu>} />
                <Route path="/customer" element={<Menu name="Customer" socket={socket}><Customer /></Menu>} />
                <Route path="/customers" element={<Menu name="Customers" socket={socket}><Customers socket={socket} /></Menu>} />
                <Route path="/loan" element={<Menu name="Loan" socket={socket}><Loan /></Menu>} />
                <Route path="/loans" element={<Menu name="Loans" socket={socket}><Loans socket={socket} /></Menu>} />
                <Route path="/insurance" element={<Menu name="Insurance" socket={socket}><Insurance /></Menu>} />
                <Route path="/insurances" element={<Menu name="Insurances" socket={socket}><Insurances socket={socket} /></Menu>} />
                <Route path="/ccs" element={<Menu name="Credit Cards" socket={socket}><CCs socket={socket} /></Menu>} />
                <Route path="/cibilscore" element={<Menu name="CIBIL Score Inquiry" socket={socket}><CibilScore /></Menu>} />
                <Route path="/cibilscores" element={<Menu name="CIBIL Score Inquiries" socket={socket}><CibilScores /></Menu>} />
                <Route path="/contact" element={<Menu name="Contact Query" socket={socket}><Contact /></Menu>} />
                <Route path="/contacts" element={<Menu name="Contact Queries" socket={socket}><Contacts socket={socket} /></Menu>} />
                <Route path="/subscriber" element={<Menu name="Subscriber" socket={socket}><Subscriber /></Menu>} />
                <Route path="/subscribers" element={<Menu name="Subscribers" socket={socket}><Subscribers socket={socket} /></Menu>} />
                <Route path="/changePassword" element={<Menu name="Change Password" socket={socket}><ChangePass /></Menu>} />
                <Route path="/profile" element={<Menu name="Profile" socket={socket}><Profile /></Menu>} />
                <Route path="/extra" element={<Menu name="Extra" socket={socket}><Extra /></Menu>} />
                <Route path="/login" element={<Login />} />

                <Route path="/*" element={<Menu name="Error Page"><Error /></Menu>} />
            </Routes>
        </BrowserRouter>
    );
}