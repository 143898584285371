import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CustomTable from "../Common/CustomTable";
import { ccs } from "../api/cc";
import { getLocalStorage } from "../Common/cookie";
import { getAdmin } from "../api/admin";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

function CCs(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [search, setSearch] = useState({
    email: "",
    name: "",
    phone: "",
    cc_type: "",
  });
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
      socket.on('creditcard', (newObject) => {
          setData(data => {
              const filteredArray = data.filter(obj => obj.id !== newObject.id);
              return [...filteredArray, newObject];
          });
      });
  }, [socket])

  useEffect(() => {
      const asyncFn = async () => {
          const checkCookie = getLocalStorage('rv-admin')
          if (checkCookie) {
              const response = await getAdmin({
                  admin_id: checkCookie.id,
                  token: checkCookie?.accessToken
              })
              if (response?.status === 200) {
                  setAdminData(response.data)
                  getAllCCs('')
              } else {
                  navigate("/login")
              }
          } else {
              navigate("/login")
          }
      };
      try{
          asyncFn();
      }catch(e){
          navigate("/login")
      }
  }, [])

  const getAllCCs = async (search) => {
      const response = await ccs({ token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken })
      if (response?.status === 200) {
          console.log("CC",response.data);
          let filtered = response.data;
          if (search.email) {
              filtered = filtered.filter(d => {
                  return d.customer.email.match(`.*${search.email}.*`);
              });
          }
          if (search.name) {
              filtered = filtered.filter(d => {
                  return d.customer.name.match(`.*${search.name}.*`);
              });
          }
          if (search.phone) {
              filtered = filtered.filter(d => {
                  return d.customer.phone.match(`.*${search.phone}.*`);
              });
          }
          if (search.cc_type) {
              filtered = filtered.filter(d => {
                  return d.cc_type === search.cc_type;
              });
          }
          setData(filtered)
      }
  }

  useEffect(() => {
      getAllCCs(search)
  }, [search]);

  const editCCData = (data) => {
      navigate("/cc?id=" + data.id + "&edit=1");
    };

  const createRows = (data) => {
      const headCells = [
          {
              id: 'id',
              label: 'Id',
          },
          {
              id: 'name',
              label: 'Name',
          },
          {
              id: 'email',
              label: 'Email',
          },
          {
              id: 'phone',
              label: 'Phone',
          },
          {
              id: 'cc_type',
              label: 'Type',
          },
          // {
          //     id: "edit",
          //     label: "",
          //     type: "button",
          //     noSort: true,
          //     icon: EditIcon,
          //     iconStyle: { color: "blue" },
          //     noDisplay: false,
          //     callback: editCCData,
          //   },
          {
              id: 'delete_all',
              noDisplay: true
          },
      ]
      function createData(id, name, email, phone, cc_type, read_time, link) {
          return {
              id, name, email, phone, cc_type, read_time, link
          };
      }

      const rows = data.map((value) => {
          return (createData(
              value.id,
              value.customer.name,
              value.customer.email,
              value.customer.phone,
              value.cc_type,
              value.read_time,
              `/cc?id=${value.id}`
          ))
      });

      const filters = <>
          <TableRow
              hover
              tabIndex={-1}
          >
              <TableCell>
                  {clear ?
                      <Tooltip title="Clear Filters" arrow>
                          <IconButton onClick={clearFilter}>
                              <CancelIcon className='primary-text' />
                          </IconButton>
                      </Tooltip>
                      :
                      <></>
                  }
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                  <TextField
                      fullWidth
                      variant="outlined"
                      name="name"
                      value={search.name}
                      onChange={onChangeData} />
              </TableCell>
              <TableCell>
                  <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      value={search.email}
                      onChange={onChangeData} />
              </TableCell>
              <TableCell>
                  <TextField
                      fullWidth
                      variant="outlined"
                      name="phone"
                      value={search.phone}
                      onChange={onChangeData} />
              </TableCell>
              <TableCell>
                  <Select
                      MenuProps={{ autoFocus: false }}
                      value={search.cc_type}
                      name="cc_type"
                      onChange={onChangeData}
                  >
                      {[{ id: '', name: "All" }, 
                      { id: 'Axis', name: "Axis" }, 
                      { id: 'HDFC', name: "HDFC" }, 
                      { id: 'IDFC', name: "IDFC" }, 
                      { id: 'ICICI', name: "ICICI" }, 
                      { id: 'IndusInd', name: "IndusInd" }, 
                      { id: 'Standard Chartered', name: "Standard Chartered" }].map((option, i) => (
                          <MenuItem key={i} value={option.id}>
                              {option.name}
                          </MenuItem>
                      ))}
                  </Select>
              </TableCell>
              {/* <TableCell style={{ width: "34px" }}></TableCell> */}
          </TableRow>
      </>

      return { headCells, rows, filters };
  }

  function clearFilter() {
      setSearch({
          email: '',
          name: '',
          phone: '',
          cc_type: ''
      })
  }

  function onChangeData(e) {
      setSearch((search) => ({
          ...search,
          [e.target.name]: e.target.value,
      }));
  }

  useEffect(() => {
      let flag = 0;
      Object.keys(search).forEach(function (key) {
          if (search[key] !== '') {
              flag = 1;
          }
      });
      flag === 0 ? setClear(false) : setClear(true);
  }, [search])

  useEffect(() => {
      let f_data = [];
      if (data.length > 0) {
        data.map((d) => {
          console.log(d);
          f_data.push({
              id: d.id,
              cc_type: d.cc_type,
              customer_id: d.customer.id,
              phone: d.customer.phone,
              name: d.customer.name,
              email: d.customer.email,
              start_date: new Date(d.created_at)
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("-"),
              ref_id: d.ref_id,
          });
        });
      }
      setDownloadData(f_data);
    }, [data]);

    const handleExportRows = () => {
      if (downloadData.length > 0) {
        const doc = new jsPDF();
        const tableData = downloadData.map((row) => Object.values(row));
        const tableHeaders = Object.keys(downloadData[0]);

        autoTable(doc, {
          head: [tableHeaders],
          body: tableData,
        });

        doc.save("ccs.pdf");
      } else {
        Swal.fire({
          title: "Caution",
          text: "You can not download 0 records.",
          icon: "warning",
        });
      }
    };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-start">
          <Grid>
            <CSVLink
              data={downloadData}
              filename={"ccs.csv"}
              className="btn secondary-btn mr-1"
              target="_blank"
            >
              <DownloadIcon /> CSV
            </CSVLink>
            <Button
              onClick={handleExportRows}
              className="btn secondary-btn"
              variant="outlined"
            >
              <DownloadIcon /> PDF
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box component={Paper} sx={{ pt: 2 }}>
            <CustomTable {...createRows(data)} hideDelete={true} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default CCs;
