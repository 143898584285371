import axios from "axios";

//subscribes
export const subscribes = (obj) => {
    return axios
        .get("/subscribe", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get subscribe
export const getSubscribe = (obj) => {
    return axios
        .get("/subscribe/" + obj.subscribe_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//send mail
export const sendMail = (obj) => {
    return axios
        .post("/subscribe/mail", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};