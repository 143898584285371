import axios from "axios";

//add cc
export const addCC = (obj) => {
    return axios
        .post("/creditcard", obj.data, {
            headers: {
                Authorization: `Bearer ${obj.token}` 
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//ccs
export const ccs = (obj) => {
    return axios
        .get("/creditcard", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get cc
export const getCC = (obj) => {
    return axios
        .get("/creditcard/" + obj.cc_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//read cc
export const readCC = (obj) => {
    return axios
        .patch("/creditcard/read", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};