import {
  AccordionDetails,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect, useState } from "react";
import {
  validateDateOfBirth,
  validateEmail,
  validateName,
  validateSelect,
} from "../Common/validations";
import { personalDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import user from "../Assets/Image/user.png";
import { getLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";
import { convertUTCToIST } from "../Common/fun";
function CustPersonal(props) {
  const { adminData, id, edit } = props;
  const [image, setImage] = useState({ preview: "", raw: "" }); //for profile image
  const [gender, setGender] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const getCustData = async () => {
    const response = await getCustomer({
      customer_id: id,
      token: adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
    });
    if (response?.status === 200) {
      response.data.dob = convertUTCToIST(response.data.dob);
      setData(response.data);
      setGender(response.data.gender);
      setMaritalStatus(response.data.marital_status);
    }
  };

  useEffect(() => {
    getCustData();
  }, [adminData, id, edit]);

  const handleEditClick = () => {
    document.getElementById("upload-input").click();
  };

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }
  function validateFName(str) {
    setErrors((errors) => ({
      ...errors,
      name: validateName(str).error,
    }));
  }
  function validateDOB(str) {
    setErrors((errors) => ({
      ...errors,
      dob: validateDateOfBirth(str).error,
    }));
  }
  function validateGender(str) {
    setErrors((errors) => ({
      ...errors,
      gender: validateSelect(str).error,
    }));
  }
  function validateMaritalStatus(str) {
    setErrors((errors) => ({
      ...errors,
      marital_status: validateSelect(str).error,
    }));
  }

  function validateForm() {
    validateEmailAddr(data.email);
    validateFName(data.name);
    validateDOB(data.dob);
    validateGender(gender);
    validateMaritalStatus(maritalStatus);
    let valid =
      validateEmail(data.email).valid &&
      validateName(data.name).valid &&
      validateDateOfBirth(data.dob).valid &&
      validateSelect(gender).valid &&
      validateSelect(maritalStatus).valid;
    return valid;
  }

  async function saveData() {
    // e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("id", data.id || getLocalStorage("rv-user")?.id);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("dob", data.dob);
      formData.append("gender", gender);
      formData.append("marital_status", maritalStatus);
      formData.append("photo", image.raw ? image.raw : null);

      const response = await personalDetails({
        token:
          adminData?.accessToken || getLocalStorage("rv-admin")?.accessToken,
        data: formData,
      });
      if (response?.status === 200) {
        Swal.fire("Good Job!", "Customer data updated successfully", "success");
        getCustData();
      }
    }
  }
  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <Table>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Contact No.</TableCell>
              <TableCell style={{ width: "80%" }}>
                <>
                  {data.phone} {data.phone_verified ? <VerifiedIcon /> : null}
                </>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Name</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="name"
                    value={data.name}
                    onChange={onChangeData}
                    onBlur={() => validateFName(data.name)}
                  />
                ) : (
                  data.name
                )}
                {errors.name && edit && (
                  <Typography className="error-text">{errors.name}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Email</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <TextField
                    name="email"
                    value={data.email}
                    onChange={onChangeData}
                    onBlur={() => validateEmailAddr(data.email)}
                  />
                ) : (
                  data.email
                )}
                {errors.email && edit && (
                  <Typography className="error-text">{errors.email}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>DOB</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <input
                    type="date"
                    name="dob"
                    value={data.dob && data.dob.split("T")[0]}
                    onChange={onChangeData}
                    onBlur={() => validateDateOfBirth(data.dob)}
                  />
                ) : data?.dob ? (
                  new Date(data.dob).toLocaleDateString("en-GB")
                ) : null}
                {errors.dob && edit && (
                  <Typography className="error-text">{errors.dob}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Gender</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <Select
                    MenuProps={{ autoFocus: false }}
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    onBlur={() => validateGender(gender)}
                  >
                    {["female", "male", "other"].map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option.charAt(0).toUpperCase() + option.substring(1)}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  data.gender &&
                  data.gender.charAt(0).toUpperCase() + data.gender.substring(1)
                )}
                {errors.gender && edit && (
                  <Typography className="error-text">
                    {errors.gender}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Marital Status</TableCell>
              <TableCell style={{ width: "80%" }}>
                {edit ? (
                  <Select
                    MenuProps={{ autoFocus: false }}
                    value={maritalStatus}
                    onChange={(e) => {
                      setMaritalStatus(e.target.value);
                    }}
                    onBlur={() => validateMaritalStatus(maritalStatus)}
                  >
                    {["married", "unmarried"].map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option.charAt(0).toUpperCase() + option.substring(1)}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  data.marital_status &&
                  data.marital_status.charAt(0).toUpperCase() +
                    data.marital_status.substring(1)
                )}
                {errors.marital_status && edit && (
                  <Typography className="error-text">
                    {errors.marital_status}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            {/* <TableRow>
                            <TableCell style={{ width: "20%" }}>Aadhar No</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="aadhar_no"
                                value={data.aadhar_no}
                                onChange={onChangeData}
                                onBlur={() => validateFirstName(data.aadhar_no)}
                            /> : data.aadhar_no}
                                {errors.aadhar_no && (
                                    <Typography className='error-text'>{errors.aadhar_no}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>PAN</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="pan_no"
                                value={data.pan_no}
                                onChange={onChangeData}
                            // onBlur={() => validateFirstName(data.f_name)}
                            /> : data.pan_no}
                                {errors.pan_no && (
                                    <Typography className='error-text'>{errors.pan_no}</Typography>
                                )}
                            </TableCell>
                        </TableRow> */}
          </Table>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box
            component={Paper}
            className="image-preview"
            style={{
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              maxHeight: "380px",
              position: "relative",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            {image.preview ? (
              <img
                src={image.preview}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            ) : data.profile ? (
              <img
                src={`${process.env.REACT_APP_API}${data.profile}`}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={user}
                alt="Preview"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {edit && (
              <div
                className="edit-icon"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  cursor: "pointer",
                  background: "rgba(255, 255, 255, 0.8)",
                  padding: "5px",
                }}
                onClick={handleEditClick}
              >
                <CameraAltIcon className="primary-text" />
              </div>
            )}
          </Box>
          <input
            type="file"
            id="upload-input"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </Grid>
        {edit && (
          <Button
            sx={{ ml: 2 }}
            className="primary-btn"
            variant="contained"
            onClick={saveData}
          >
            {" "}
            Save
          </Button>
        )}
      </Grid>
    </AccordionDetails>
  );
}
export default CustPersonal;
