import { Box, Button, Grid, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { validateMax, validateMin } from "../Common/validations";
import { getEmiData, changeAmountApi, changeInterestApi, changeTenureApi } from '../api/emiCalc'

function EMICalc(props) {
    const { adminData } = props;
    const [editAmount, setEditAmount] = useState(false)
    const [editInterest, setEditInterest] = useState(false)
    const [editTenure, setEditTenure] = useState(false)
    const [amountData, setAmountData] = useState({})
    const [tenureData, setTenureData] = useState({})
    const [interestData, setInterestData] = useState({})
    const [amountError, setAmountError] = useState({})
    const [tenureError, setTenureError] = useState({})
    const [interestError, setInterestError] = useState({})

    useEffect(() => {
        const asyncFn = async () => {
            getData()
        }
        asyncFn();
    }, [])

    const getData = async () => {
        const response = await getEmiData()
        if (response?.status === 200) {
            setAmountData(response.data.find(obj => obj.name === "amount"));
            setTenureData(response.data.find(obj => obj.name === "tenure"));
            setInterestData(response.data.find(obj => obj.name === "interest"));
        }
    }

    function onChangeAmountData(e) {
        setAmountData((amountData) => ({
            ...amountData,
            [e.target.name]: e.target.value,
        }));
    }

    function validateMinAmount(str) {
        setAmountError((amountError) => ({
            ...amountError,
            min_value: validateMin(str).error,
        }))
    }
    function validateMaxAmount(min, max) {
        setAmountError((amountError) => ({
            ...amountError,
            max_value: validateMax(min, max).error,
        }))
    }

    function validateFormAmount() {
        validateMinAmount(amountData.min_value)
        validateMaxAmount(amountData.min_value, amountData.max_value)

        let valid =
            validateMin(amountData.min_value).valid &&
            validateMax(amountData.min_value, amountData.max_value).valid;
        return valid;
    }

    const changeAmount = async (e) => {
        e.preventDefault();
        if (editAmount) {
            if (validateFormAmount()) {
                const response = await changeAmountApi({
                    data: {
                        min_value: amountData.min_value,
                        max_value: amountData.max_value
                    },
                    token: adminData?.accessToken
                })
                if (response?.status === 200) {
                    getData()
                    setEditAmount(!editAmount)
                }
            }
        } else {
            setEditAmount(!editAmount)
        }
    }

    function onChangeInterestData(e) {
        setInterestData((interestData) => ({
            ...interestData,
            [e.target.name]: e.target.value,
        }));
    }

    function validateMinInterest(str) {
        setInterestError((interestError) => ({
            ...interestError,
            min_value: validateMin(str).error,
        }))
    }
    function validateMaxInterest(min, max) {
        setInterestError((interestError) => ({
            ...interestError,
            max_value: validateMax(min, max).error,
        }))
    }

    function validateFormInterest() {
        validateMinInterest(interestData.min_value)
        validateMaxInterest(interestData.min_value, interestData.max_value)

        let valid =
            validateMin(interestData.min_value).valid &&
            validateMax(interestData.min_value, interestData.max_value).valid;
        return valid;
    }

    const changeInterest = async (e) => {
        e.preventDefault();
        if (editInterest) {
            if (validateFormInterest()) {
                const response = await changeInterestApi({
                    data: {
                        min_value: interestData.min_value,
                        max_value: interestData.max_value
                    },
                    token: adminData?.accessToken
                })
                if (response?.status === 200) {
                    getData()
                    setEditInterest(!editInterest)
                }
            }
        } else {
            setEditInterest(!editInterest)
        }
    }

    function onChangeTenureData(e) {
        setTenureData((tenureData) => ({
            ...tenureData,
            [e.target.name]: e.target.value,
        }));
    }

    function validateMinTenure(str) {
        setTenureError((tenureError) => ({
            ...tenureError,
            min_value: validateMin(str).error,
        }))
    }
    function validateMaxTenure(min, max) {
        setTenureError((tenureError) => ({
            ...tenureError,
            max_value: validateMax(min, max).error,
        }))
    }

    function validateFormTenure() {
        validateMinTenure(tenureData.min_value)
        validateMaxTenure(tenureData.min_value, tenureData.max_value)

        let valid =
            validateMin(tenureData.min_value).valid &&
            validateMax(tenureData.min_value, tenureData.max_value).valid;
        return valid;
    }

    const changeTenure = async (e) => {
        e.preventDefault();
        if (editTenure) {
            if (validateFormTenure()) {
                const response = await changeTenureApi({
                    data: {
                        min_value: tenureData.min_value,
                        max_value: tenureData.max_value
                    },
                    token: adminData?.accessToken
                })
                if (response?.status === 200) {
                    getData()
                    setEditTenure(!editTenure)
                }
            }
        } else {
            setEditTenure(!editTenure)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ p: 2 }}>
                <Box sx={{ pl: 2 }}>
                    <Grid item xs={12} className="d-flex justify-content-between mb-2">
                        <Typography style={{ fontWeight: "700", marginTop: "7px" }}>Amount</Typography>
                        <Button className='primary-btn' variant="contained" onClick={changeAmount}>{editAmount ? 'Update' : 'Edit'} </Button>
                    </Grid>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Minimum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editAmount ? <TextField
                                name="min_value"
                                value={amountData.min_value}
                                onChange={onChangeAmountData}
                                onBlur={() => validateMinAmount(amountData.min_value)}
                            /> : amountData.min_value}
                                {amountError.min_value && (
                                    <Typography className='error-text'>{amountError.min_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Maximum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editAmount ? <TextField
                                name="max_value"
                                value={amountData.max_value}
                                onChange={onChangeAmountData}
                                onBlur={() => validateMaxAmount(amountData.min_value, amountData.max_value)}
                            /> : amountData.max_value}
                                {amountError.max_value && (
                                    <Typography className='error-text'>{amountError.max_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    </Table>

                    <Grid item xs={12} className="d-flex justify-content-between mb-2">
                        <Typography style={{ fontWeight: "700", marginTop: "7px" }}>Interest</Typography>
                        <Button className='primary-btn' variant="contained" onClick={changeInterest}>{editInterest ? 'Update' : 'Edit'} </Button>
                    </Grid>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Minimum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editInterest ? <TextField
                                name="min_value"
                                value={interestData.min_value}
                                onChange={onChangeInterestData}
                                onBlur={() => validateMinInterest(interestData.min_value)}
                            /> : interestData.min_value}
                                {interestError.min_value && (
                                    <Typography className='error-text'>{interestError.min_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Maximum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editInterest ? <TextField
                                name="max_value"
                                value={interestData.max_value}
                                onChange={onChangeInterestData}
                                onBlur={() => validateMaxInterest(interestData.min_value, interestData.max_value)}
                            /> : interestData.max_value}
                                {interestError.max_value && (
                                    <Typography className='error-text'>{interestError.max_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    </Table>

                    <Grid item xs={12} className="d-flex justify-content-between mb-2">
                        <Typography style={{ fontWeight: "700", marginTop: "7px" }}>Tenure</Typography>
                        <Button className='primary-btn' variant="contained" onClick={changeTenure}>{editTenure ? 'Update' : 'Edit'} </Button>
                    </Grid>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Minimum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editTenure ? <TextField
                                name="min_value"
                                value={tenureData.min_value}
                                onChange={onChangeTenureData}
                                onBlur={() => validateMinTenure(tenureData.min_value)}
                            /> : tenureData.min_value}
                                {tenureError.min_value && (
                                    <Typography className='error-text'>{tenureError.min_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Maximum</TableCell>
                            <TableCell style={{ width: "80%" }}>{editTenure ? <TextField
                                name="max_value"
                                value={tenureData.max_value}
                                onChange={onChangeTenureData}
                                onBlur={() => validateMaxTenure(tenureData.min_value, tenureData.max_value)}
                            /> : tenureData.max_value}
                                {tenureError.max_value && (
                                    <Typography className='error-text'>{tenureError.max_value}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    </Table>
                </Box>
            </Grid>
        </Grid>
    )
}

export default EMICalc;