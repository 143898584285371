import { AccordionDetails, Autocomplete, Button, Grid, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getCustomer } from "../api/customer";
import { validateText, validatePincode } from "../Common/validations";
import { addressDetails } from "../api/customerAuth";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from '../api/location'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
function CustAddress(props) {
    const { adminData, id, edit } = props;
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})

    function onChangeData(e) {
        if (e.target.name === "pincode") {
            setLocation(e.target.value)
        }
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        const asyncFn = async () => {
            const response = await getCustomer({
                customer_id: id,
                token: adminData?.accessToken || getLocalStorage('rv-admin')?.accessToken
            })
            if (response?.status === 200) {
                setData(response.data);
                if (response.data.pincode) {
                    await setLocation(response.data.pincode)
                }
            }
        }
        try {
            asyncFn();
        } catch (e) {
            navigate("/login")
        }
    }, [adminData, id, edit])

    const setLocation = async (pincode) => {
        if (pincode && pincode.length === 6) {
            const location = await getLocation({
                pincode: pincode
            })
            if (location[0]?.Status === "Success") {
                const lData = location[0].PostOffice[0]
                setData((data) => ({
                    ...data,
                    city: lData.District,
                    state: lData.State,
                    country: lData.Country
                }));
                setErrors((errors) => ({
                    ...errors,
                    pincode: '',
                }));
            } else {
                setErrors((errors) => ({
                    ...errors,
                    pincode: 'Please enter valid Pin Code',
                }));
            }
        };
    }

    function validateAddr1(str) {
        setErrors((errors) => ({
            ...errors,
            addressline1: validateText(str).error,
        }));
    }
    function validateArea(str) {
        setErrors((errors) => ({
            ...errors,
            area: validateText(str).error,
        }));
    }
    function validatePin(str) {
        setErrors((errors) => ({
            ...errors,
            pincode: validatePincode(str).error,
        }));
    }

    function validateForm() {
        validateAddr1(data.addressline1)
        validateArea(data.area)
        validatePin(data.pincode)
        let valid = validateText(data.addressline1).valid
            && validateText(data.area).valid
            && validatePincode(data.pincode).valid
        return valid;
    }

    async function saveData(e) {
        e.preventDefault();
        if (!errors.pincode) {
            if (validateForm()) {
                const response = await addressDetails({
                    token: adminData?.accessToken || getLocalStorage('rv-admin')?.accessToken,
                    data: {
                        id: id,
                        addressline1: data.addressline1,
                        addressline2: data.addressline2,
                        area: data.area,
                        pincode: data.pincode,
                        city: data.city,
                        state: data.state,
                        country: data.country
                    },
                })
                if (response?.status === 200) {
                    Swal.fire(
                        'Good Job!',
                        'Customer data updated successfully',
                        'success'
                    )
                }
            }
        }
    }
    return (
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Addressline 1</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="addressline1"
                                value={data.addressline1}
                                onChange={onChangeData}
                                onBlur={() => validateAddr1(data.addressline1)}
                            /> : data.addressline1}
                                {errors.addressline1 && edit && (
                                    <Typography className='error-text'>{errors.addressline1}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Addressline 2</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="addressline2"
                                value={data.addressline2}
                                onChange={onChangeData}
                            /> : data.addressline2}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Area</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="area"
                                value={data.area}
                                onChange={onChangeData}
                                onBlur={() => validateArea(data.area)}
                            /> : data.area}
                                {errors.area && edit && (
                                    <Typography className='error-text'>{errors.area}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Pin Code</TableCell>
                            <TableCell style={{ width: "80%" }}>{edit ? <TextField
                                name="pincode"
                                value={data.pincode}
                                onChange={onChangeData}
                                onBlur={() => validatePin(data.pincode)}
                            /> : data.pincode}
                                {errors.pincode && edit && (
                                    <Typography className='error-text'>{errors.pincode}</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>City</TableCell>
                            <TableCell style={{ width: "80%" }}>{data.city}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>State</TableCell>
                            <TableCell style={{ width: "80%" }}>{data.state}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Country</TableCell>
                            <TableCell style={{ width: "80%" }}>{data.country}</TableCell>
                        </TableRow>
                    </Table>
                    {edit && <Button className='primary-btn' variant="contained" onClick={saveData} > Save</Button>}
                </Grid>
            </Grid>
        </AccordionDetails>
    )
}
export default CustAddress;