import axios from "axios";

//registration
export const adminReg = (obj) => {
  return axios
    .post("/admin-auth/register", obj.data, {
      headers: {
        Authorization: `Bearer ${obj.token}`,
        "content-type": "multipart/form-data"
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//login
export const adminLogin = (obj) => {
  return axios
    .post("/admin-auth/login", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//Change Password
export const adminChangePassword = (obj) => {
  return axios
    .post('/admin-auth/change-password', obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//Forgot Set Password
export const adminForgotSetPassword = (obj) => {
  return axios
    .patch("/admin-auth/set-password", obj.a_data, {
      headers: { Authorization: `Bearer ${obj.token}` }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
