import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage} from '../Common/cookie'

import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EMICalc from '../Components/emiCalc'
import CustDoc from "../Components/custDoc";
import { getAdmin } from "../api/admin";

function Extra(props) {
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [adminData, setAdminData] = useState({})  //for authentication token   

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }
        };
        try{
            asyncFn();
        }catch(e){
            navigate("/login")
        }
    }, [])

    return (
        <>
            <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12} className="d-flex justify-content-between">
                    {/* <Link className='text-decoration-none' to='/customers'><Button className='primary-btn' variant="contained" > Back</Button></Link>
                    {id && !edit && <Link className='text-decoration-none' to={`/customer?id=${id}&edit=1`}><Button className='primary-btn' variant="contained" > Edit</Button></Link>} */}
                </Grid>
                <div style={{ margin: "16px", marginRight: "0", width: "100%" }}>
                    {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ flexShrink: 0 }}>
                                Customer Documents
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <CustDoc adminData={adminData} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion> */}
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ flexShrink: 0 }}>
                                Min Max Value for EMI Calculator
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <EMICalc adminData={adminData} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                   
                </div>
            </Grid>
        </>
    )
}

export default Extra;
