import axios from "axios";

//contacts
export const contacts = (obj) => {
    return axios
        .get("/contact", {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//get contact
export const getContact = (obj) => {
    return axios
        .get("/contact/" + obj.contact_id, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//read contact
export const readContact = (obj) => {
    return axios
        .patch("/contact/read", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};

//reply contact
export const replyContact = (obj) => {
    return axios
        .patch("/contact/reply", obj.data, {
            headers: { Authorization: `Bearer ${obj.token}` },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Error", err.response.data.message);
            return err.response.data;
        });
};
