import { Button } from '@mui/material';
import ErrorImg from '../Assets/Image/404.png'
function Error() {
    return (
        <>
            <div className='d-flex justify-content-center'>
                <img src={ErrorImg} alt="" className='error-img' />

            </div>
            <div className='d-flex justify-content-center'>
                <Button variant="contained" href="/">
                    Go To Dashboard
                </Button>
            </div>
        </>
    )
}
export default Error;