import { Box, Button, Grid, Table, TableCell, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { subscribes, sendMail } from '../api/subscribe'
import { getLocalStorage} from '../Common/cookie'
import { getAdmin } from "../api/admin";
import { useNavigate } from 'react-router-dom';

function Subscriber() {
    const navigate = useNavigate() 
    const [adminData, setAdminData] = useState({})
    const [data, setData] = useState([])
    const [emails, setEmails] = useState([]);
    const [subject, setSubject] = useState('');
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());
    const handleChange = (newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const asyncFn = async () => {
            const checkCookie = getLocalStorage('rv-admin')
            if (checkCookie) {
                const response = await getAdmin({
                    admin_id: checkCookie.id,
                    token: checkCookie?.accessToken
                })
                if (response?.status === 200) {
                    setAdminData(response.data)
                    const res = await subscribes({ token: response.data.accessToken })
                    if (res !== undefined) {
                        if (res?.status === 200) {
                            const emails = res.data.map(obj => obj.email);
                            setData(emails)
                        }
                    }
                }
            } else {
                navigate("/login")
            }
        };
        try{
            asyncFn();
        }catch(e){
            navigate("/login")
        }
    }, [])


    const sendMails = async () => {
        const response = await sendMail({
            token: adminData?.accessToken || getLocalStorage('rv-admin').accessToken,
            data: {
                emails: emails,
                subject: subject,
                message: value.toString('html')
            }
        })
    }
    return (
        <Grid container spacing={2} sx={{ pb: 2 }}>
            <Grid item xs={12}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Table>
                        <TableRow>
                            <TableCell style={{ width: "20%" }}>Subject</TableCell>
                            <TableCell style={{ width: "80%" }}> <TextField
                                name="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                            </TableCell>
                        </TableRow>
                    </Table>
                </Box>
                <RichTextEditor value={value} onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
                <Box component="form">
                    <Button onClick={sendMails} variant="contained" className='primary-btn me-3'> Send </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Subscriber;