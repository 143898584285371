import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import CustomerData from "../Components/customerData";
import { getLoan, readLoan, updateLoanData } from "../api/loan";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getAdmin } from "../api/admin";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  validateDate,
  validateMax,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { customers } from "../api/customer";
import { getInsurance, readInsurance, updateInsurance } from "../api/insurance";
import { convertUTCToIST } from "../Common/fun";
function Insurance() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const edit = new URLSearchParams(search).get("edit");

  const [adminData, setAdminData] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [selectedCust, setSelectedCust] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [agreementDB, setAgreementDB] = useState("");
  const [instantLoanTenure, setInstantLoanTenure] = useState([]);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-admin");
      if (checkCookie) {
        const response = await getAdmin({
          admin_id: checkCookie.id,
          token: checkCookie?.accessToken,
        });
        if (response?.status === 200) {
          setAdminData(response.data);
          if (id) {
            const res = await getInsurance({
              insurance_id: id,
              token: response.data.accessToken,
            });
            if (res?.status === 200) {
              res.data.start_date = res.data.start_date ? convertUTCToIST(res.data.start_date) : null
              res.data.end_date = res.data.end_date ? convertUTCToIST(res.data.end_date) : null
              setData(res.data);
            }
            if (!data.read_time || !data.admin_id) {
              const res1 = await readInsurance({
                token: response.data.accessToken,
                data: {
                  id: id,
                  admin_id: response.data.id,
                },
              });
            }
          } else {
            const cust = await customers({
              token:
                checkCookie?.accessToken ||
                getLocalStorage("rv-admin").accessToken,
            });
            if (cust?.status === 200) {
              setCustomersData(cust.data);
            }
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    try {
      asyncFn();
    } catch (e) {
      navigate("/login");
    }
  }, []);

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const insuranceTypes = ["Life", "Health", "Car"];

  function validateCustomer(str) {
    setErrors((errors) => ({
      ...errors,
      customer_id: validateSelect(str).error,
    }));
  }
  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      type: validateSelect(str).error,
    }));
  }
  function validateNominee(str) {
    setErrors((errors) => ({
      ...errors,
      nominee: validateText(str).error,
    }));
  }
  function validateRelation(str) {
    setErrors((errors) => ({
      ...errors,
      relation: validateText(str).error,
    }));
  }
  function validateAmount(str) {
    setErrors((errors) => ({
      ...errors,
      amount: validateSalary(str).error,
    }));
  }
  function validatePremium(str) {
    setErrors((errors) => ({
      ...errors,
      premium: validateSalary(str).error,
    }));
  }
  function validateStartDate(str) {
    setErrors((errors) => ({
      ...errors,
      start_date: validateDate(str).error,
    }));
  }
  function validateEndDate(str) {
    setErrors((errors) => ({
      ...errors,
      end_date: validateDate(str).error,
    }));
  }

  function validateAddForm() {
    validateAmount(data.amount);
    validatePremium(data.premium);
    validateStartDate(data.start_date);
    if(data?.end_date){
      validateEndDate(data.end_date);
    }
    if (!id) {
      validateCustomer(selectedCust);
      validateType(data.type);
    }
    if (data.type === "Life") {
      validateNominee(data.nominee);
      validateRelation(data.relation);
    }

    let valid =
        validateSalary(data.amount).valid &&
        validateSalary(data.premium).valid &&
        validateDate(data.start_date).valid &&
      (data?.end_date
        ? validateDate(data.end_date).valid 
        : true) &&
      (!id
        ? validateSelect(selectedCust).valid && validateSelect(data.type).valid
        : true) &&
      (data.type === "Life"
        ? validateText(data.nominee).valid && validateText(data.relation).valid
        : true);
    return valid;
  }

  const findOptionById = (options, id) => {
    return options.find((option) => option.id == id);
  };

  const addData = async (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      const formData = new FormData();
      formData.append("id", id ? id : null);
      formData.append("amount", data.amount);
      formData.append("premium", data.premium);
      formData.append("start_date", data.start_date);
      formData.append("end_date", data?.end_date ? data.end_date : null);
      // formData.append(
      //   "admin_id",
      //   adminData.id || getLocalStorage("rv-admin")?.id
      // );
      // formData.append(
      //   "customer_id",
      //   selectedCust ? selectedCust : data.customer_id
      // );

      const response = await updateInsurance({
        data: formData,
        token:
          adminData.accessToken || getLocalStorage("rv-admin")?.accessToken,
      });
      if (response?.status === 200) {
        if (id) {
          navigate(`/insurance?id=${id}`);
        } else {
          navigate("/insurances");
        }
      } else {
        Swal.fire("Oops!", "Something went wrong...", "error");
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Link className="text-decoration-none" to="/insurances">
            <Button className="primary-btn" variant="contained">
              Back
            </Button>
          </Link>
          {id && !edit && (
            <Link
              className="text-decoration-none"
              to={`/insurance?id=${id}&edit=1`}
            >
              <Button className="primary-btn" variant="contained">
                Edit
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} sx={{ overflow: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
                p: 2,
              }}
            >
              <Table>
                {!(!id && edit) && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Name</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.name}</>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>
                        Contact No.
                      </TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>
                          {data?.customer?.phone}
                          {data?.customer?.phone_verified ? (
                            <VerifiedIcon />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Email</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        <>{data?.customer?.email}</>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!id && edit && (
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Customer</TableCell>
                    <TableCell style={{ width: "80%" }}>
                      <Autocomplete
                        value={
                          findOptionById(customersData, selectedCust) || null
                        } // Ensure value is not null
                        onChange={(event, newValue) => {
                          setSelectedCust(newValue ? newValue.id : null);
                        }}
                        onBlur={() => validateCustomer(selectedCust)}
                        options={
                          !customersData
                            ? [{ label: "Loading...", id: 0 }]
                            : customersData
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Insurance Type</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit && !id ? (
                      <Select
                        value={data.type}
                        name="type"
                        onChange={onChangeData}
                        onBlur={() => validateType(data.type)}
                      >
                        {insuranceTypes.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <b>{data.type}</b>
                    )}
                    {errors.type && edit && (
                      <Typography className="error-text">
                        {errors.type}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                {data.type === "Life" && (
                  <>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Nominee</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit && !id ? (
                          <TextField
                            name="nominee"
                            value={data.nominee}
                            onChange={onChangeData}
                            onBlur={() => validateNominee(data.nominee)}
                          />
                        ) : (
                          <>{data?.nominee}</>
                        )}
                        {errors.nominee && edit && (
                          <Typography className="error-text">
                            {errors.nominee}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>Relation</TableCell>
                      <TableCell style={{ width: "80%" }}>
                        {edit && !id ? (
                          <TextField
                            value={data.relation}
                            name="relation"
                            onChange={onChangeData}
                            onBlur={() => validateRelation(data.relation)}
                          />
                        ) : (
                          <>{data?.relation}</>
                        )}
                        {errors.relation && edit && (
                          <Typography className="error-text">
                            {errors.relation}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Insurance Amount
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <div className="d-flex">
                        <TextField
                          name="amount"
                          value={data.amount}
                          onChange={onChangeData}
                            onBlur={() =>
                              validateAmount(data.amount)
                            }
                        />
                      </div>
                    ) : (
                      <b>
                        {data.amount}
                      </b>
                    )}
                    {errors.amount && edit && (
                      <Typography className="error-text">
                        {errors.amount}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Insurance Premium
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <div className="d-flex">
                        <TextField
                          name="premium"
                          value={data.premium}
                          onChange={onChangeData}
                            onBlur={() =>
                              validatePremium(data.premium)
                            }
                        />
                      </div>
                    ) : (
                      <b>
                        {data.premium}
                      </b>
                    )}
                    {errors.premium && edit && (
                      <Typography className="error-text">
                        {errors.premium}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>Start Date</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                      <input
                      type="date"
                      name="start_date"
                      value={data.start_date && data.start_date.split("T")[0]}
                      onChange={onChangeData}
                      // onBlur={() => validateDateOfBirth(data.start_date)}
                    />
                    ) : data?.start_date ? (
                      new Date(data.start_date).toLocaleDateString("en-GB")
                    ) : null}
                    {errors.start_date && edit && (
                      <Typography className="error-text">
                        {errors.start_date}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>End Date</TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {edit ? (
                     <input
                     type="date"
                     name="end_date"
                     value={data.end_date && data.end_date.split("T")[0]}
                     onChange={onChangeData}
                     // onBlur={() => validateDateOfBirth(data.end_date)}
                   />
                    ) : data?.end_date ? (
                      new Date(data.end_date).toLocaleDateString("en-GB")
                    ) : null}
                    {errors.end_date && edit && (
                      <Typography className="error-text">
                        {errors.end_date}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Box>
        </Grid>
        <Box component="form" sx={{ ml: 3 }}>
          {id && edit && (
            <>
              <Button
                sx={{ mt: 3 }}
                onClick={addData}
                variant="contained"
                className="primary-btn me-3"
              >
                Update
              </Button>
              <Link className="text-decoration-none" to={`/insurance?id=${id}`}>
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  className="primary-btn"
                >
                  Cancel
                </Button>
              </Link>
            </>
          )}
          {!id && edit && (
            <Button
              sx={{ mt: 3 }}
              onClick={addData}
              variant="contained"
              className="primary-btn"
            >
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {id && (
        <CustomerData
          adminData={adminData}
          id={data?.customer_id}
          edit={null}
        />
      )}
    </>
  );
}
export default Insurance;
