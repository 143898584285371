import { Grid, Paper, Typography } from "@mui/material";
import { customers } from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loans } from "../api/loan";
import dash from "../Assets/Image/dashboard.png";
import { insurances } from "../api/insurance";
import { ccs } from "../api/cc";
function AdminHome(props) {
  const { socket } = props;
  const navigate = useNavigate();
  const [cust, setCust] = useState(0);
  const [loan, setLoan] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [creditcard, setCreditcard] = useState(0);

  useEffect(() => {
    socket.on("contact", (data1) => {
      getData();
    });
    socket.on("loan", (data1) => {
      getData();
    });
    socket.on("insurance", (data1) => {
      getData();
    });
    socket.on("creditcard", (data1) => {
      getData();
    });
  }, [socket]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const checkCookie = getLocalStorage("rv-admin");
    if (checkCookie) {
      const resCust = await customers({ token: checkCookie?.accessToken });
      if (resCust?.status === 200) {
        setCust(resCust.data?.length);
      }
      const resLoan = await loans({ token: checkCookie?.accessToken });
      if (resLoan?.status === 200) {
        setLoan(resLoan.data?.length);
      }
      const resQuery = await insurances({ token: checkCookie?.accessToken });
      if (resQuery?.status === 200) {
        setInsurance(resQuery.data?.length);
      }
      const resSub = await ccs({ token: checkCookie?.accessToken });
      if (resSub?.status === 200) {
        setCreditcard(resSub.data?.length);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid xs={12} sx={{ p: 1 }}>
          <div
            className="d-flex justify-content-between primary-light-bg"
            style={{ borderRadius: "16px" }}
          >
            <div className="d-flex align-items-center">
              <div className="ml-3">
                <h3>Hello, </h3>
                <h6>Welcome to Your Dashboard!</h6>
              </div>
            </div>
            <div>
              <img
                src={dash}
                style={{ maxHeight: "150px", mixBlendMode: "multiply" }}
                alt="dashboard"
              />
            </div>
          </div>
        </Grid>
        <Grid lg={3} md={6} xs={12} sx={{ p: 1 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div className="countDashboard">{cust}</div>
            <Typography>Customers</Typography>
          </Paper>
        </Grid>
        <Grid lg={3} md={6} xs={12} sx={{ p: 1 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div className="countDashboard">{loan}</div>
            <Typography>Loans</Typography>
          </Paper>
        </Grid>
        <Grid lg={3} md={6} xs={12} sx={{ p: 1 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div className="countDashboard">{insurance}</div>
            <Typography>Insurances</Typography>
          </Paper>
        </Grid>
        <Grid lg={3} md={6} xs={12} sx={{ p: 1 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div className="countDashboard">{creditcard}</div>
            <Typography>Credit Cards</Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
export default AdminHome;
